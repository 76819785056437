
:root{
  --primary-card-background:#fff;
  --body-gradient-2:#eef1f5;
  --body-gradient-1:#ffffff;
  --body-bg:#ffffff;
  --dark-bg:#161515;
  --secondary-bg: rgb(246, 246, 246);
  --mobile-header-bg:rgb(255 255 255 / 53%);
}


$element-border-color:#9f9f9f21;
$dark-background: #383838;
$light-grey: #adadad;
$secondary: #fbfbfb;
$primary: #008833;
$neutral:#f1f3f6;
$yellow: #f8f000;
$red: #f30000;

// --------— palette di ant --------—
$ant-orange-6: #fa8c16;
$ant-cyan-7:#08979c;
$ant-yellow-6:#fadb14;
$ant-magenta-7:#9e1068;
$ant-geekblue-7:#1d39c4;
$ant-purple-8:#391085;
$ant-purple-5:#9254de;
$ant-volcano-7:#d4380d;
$ant-volcano-9:#871400;
// --------— palette di ant --------—

$gradient1: linear-gradient(to top, var(--body-gradient-1) 0%, var(--body-gradient-2) 100%);
$shadow1:rgba($primary, 0.10) 0px 7px 29px 0px;
$shadow2:0 4px 30px rgba(0, 34, 64, 0.066);
$medium-background:rgba($primary, 0.30);
$light-background:rgba($primary, 0.04);
$primary-01: rgba($primary, 0.88);
$primary-02: rgba($primary, 0.20);
$primary-03: rgba($primary, 0.10);
$primary-04: rgba($primary, 0.05);

$border-yellow:#9fb00066;
$border-blue:#0082b066;
$border-green:#38b00066;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: var(--body-bg);

  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div{
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}
* {
box-sizing: border-box;
}
a{
  color: $primary;

  &:hover{
    color: #adadad;
  }
}
.flex-grow{
  flex-grow: 1;
}
.border-solid{
  border: solid 1px $element-border-color;
}
.border-yellow{
  border-color: $border-yellow !important;
  & *{
    border-color: $border-yellow !important;
  }
}
.border-green{
  border-color: $border-green !important;
  & *{
    border-color: $border-green !important;
  }
}
.border-blue{
  border-color: $border-blue !important;
  & *{
    border-color: $border-blue !important;
  }
}
.radius-1{
  border-radius: 5px !important;
}
.radius-2{
  border-radius: 10px !important;
}
.radius-3{
  border-radius: 15px !important;
}
.radius-4{
  border-radius: 20px !important;
}
.radius-circle{
  border-radius: 100% !important;
}
.opacity-0{
opacity: 0;
}
.opacity-01{
opacity: .1;
}
.opacity-02{
opacity: .2;
}
.opacity-03{
opacity: .3;
}
.opacity-04{
opacity: .4;
}
.opacity-05{
opacity: .5;
}
.opacity-07{
opacity: .7;
}
.opacity-08{
opacity: .8;
}
.opacity-09{
opacity: .9;
}
.fs-30{
font-size: 30px;
}
.fs-25{
font-size: 25px;
}
.fs-20{
font-size: 20px;
}
.fs-18{
font-size: 18px;
}
.fs-16{
font-size: 16px;
}
.fs-15{
font-size: 15px;
}
.fs-12{
font-size: 12px;
}
.fs-10{
font-size: 10px;
}
.fs-8{
font-size: 8px;
}
.sticky{
  position: sticky;
}
.relative{
position: relative;
}
.absolute{
position: absolute;
}
.fixed{
position: fixed;
}
.top-0{
top: 0;
}
.right-0{
right: 0;
}
.bottom-0{
bottom: 0;
}
.left-10{
left: 10px;
}
.top-10{
top: 10px;
}
.right-10{
right: 10px;
}
.bottom-10{
bottom: 10px;
}
.left-20{
left: 20px;
}
.top-20{
top: 20px;
}
.right-20{
right: 20px;
}
.right-30{
right: 30px;
}
.bottom-20{
bottom: 20px;
}
.left-20{
left: 20px;
}
.left-40{
left: 40px;
}
.top-40{
top: 40px;
}
.right-40{
right: 40px;
}
.bottom-40{
bottom: 40px;
}
.bottom-50{
bottom: 50px;
}
.bottom-80{
bottom: 80px;
}
.bottom-100{
bottom: 100px;
}
.left-90{
left: 90px;
}
.hide{
display: none !important;
}
.block{
display: block !important;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-grab{
  cursor: grab;
}
.cursor-move{
  cursor: move;
}
.flex{
display: flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.column{
flex-direction: column;
}
.gap-5{
gap:5px;
}
.gap-10{
gap:10px;
}
.gap-15{
gap:15px;
}
.gap-20{
gap:20px;
}
.gap-35{
gap:35px;
}
.my-10{
margin-top: 10px;
margin-bottom: 10px;
}
.my-15{
margin-top: 15px;
margin-bottom: 15px;
}
.my-20{
margin-top: 20px;
margin-bottom: 20px;
}
.mx-10{
margin-left: 10px;
margin-right: 10px;
}
.mx-15{
margin-left: 15px;
margin-right: 15px;
}
.mx-20{
margin-left: 20px;
margin-right: 20px;
}
.mt-5{
margin-top: 5px;
}
.mt-10{
margin-top: 10px;
}
.mt-20{
margin-top: 20px;
}
.mt-30{
margin-top: 30px;
}
.mt-40{
margin-top: 40px;
}
.mt-50{
margin-top: 50px;
}
.mt-100{
margin-top: 100px;
}
.mt-10vh{
margin-top: 10vh;
}
.mb-0{
  margin-bottom: 0 !important;
}
.mb-5{
margin-bottom: 5px;
}
.mb-10{
margin-bottom: 10px;
}
.mb-20{
margin-bottom: 20px;
}
.mb-30{
margin-bottom: 30px;
}
.m-0{
  margin: 0;
}
.p-0{
  padding: 0px;
}
.p-5{
padding: 5px;
}
.p-20{
padding: 20px;
}
.pt-5{
padding-top: 5px;
}
.pt-15{
padding-top: 15px;
}

.pt-10{
padding-top: 10px;
}
.px-10{
padding-left: 10px;
padding-right: 10px;
}
.px-15{
padding-left: 15px;
padding-right: 15px;
}
.px-25{
padding-left: 25px;
padding-right: 25px;
}
.px-30{
padding-left: 30px;
padding-right: 30px;
}
.px-40{
padding-left: 40px;
padding-right: 40px;
}
.py-10{
padding-top: 10px;
padding-bottom: 10px;
}
.py-15{
padding-top: 15px;
padding-bottom: 15px;
}
.py-25{
padding-top: 25px;
padding-bottom: 25px;
}
.py-35{
padding-top: 35px;
padding-bottom: 35px;
}
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
padding: 15px;
}
.p-25{
padding: 25px;
}
.p-15{
padding: 15px;
}
.w-100{
width: 100% !important;
}
.w-80{
width: 80% !important;
}
.w-70{
width: 70% !important;
}
.w-60{
width: 60% !important;
}
.w-50{
width: 50% !important;
}
.w-40{
width: 40% !important;
}
.w-30{
width: 30% !important;
}
.w-20{
width: 20% !important;
}
.w-10{
width: 10% !important;
}
.w-300{
  width: 300px;
}
.vw-100{
width: 100vw;
}
.min-w-unset{
max-width:unset !important;
}
.min-w-100{
 min-width: 100px;
}
.min-w-150{
 min-width: 150px;
}
.mw-100{
max-width:100%;
}
.mw-sm{
max-width:300px;
}
.mw-md{
max-width:400px;
}
.mw-lg{
max-width:500px;
}
.mw-xl{
max-width:600px;
}
.mw-xxl{
max-width:700px;
}
.align-start{
align-items: start;
}
.align-center{
align-items: center;
}
.align-end{
align-items: flex-end;
}
.space-between{
justify-content: space-between;
}
.space-around{
justify-content: space-around;
}
.justify-start{
justify-content: start;
}
.justify-center{
justify-content: center;
}
.justify-end{
justify-content: flex-end;
}
.h-100{
height: 100% !important;
}
.vh-100{
height: 100vh;
}
.text-sm{
font-size: 25px;
font-weight: 500;
}
.color-primary{
color: $primary;
}
.color-red{
color: $red;
}
.color-yellow{
color: $yellow;
}
.color-white{
color: #fff;
}
.color-black{
color: rgb(15, 15, 15);
}
.color-secondary{
color: $secondary;
}
.color-grey{
color: $light-grey;
}
.fw-4{
  font-weight:400;
}
.fw-5{
  font-weight:500;
}
.fw-6{
  font-weight:600;
}
.bold{
  font-weight: bold;
}
.capitalize{
  text-transform: lowercase !important;
  &::first-letter{
    text-transform: uppercase !important;
  }
}
.text-md{
font-size: 50px;
font-weight:700;
}
.text-xl{
font-size: 70px;
font-weight:700;
}
.text-center{
text-align: center;
}
.text-right{
text-align: right;
}
.bg-red{
  background-color: $ant-volcano-9 !important;
}
.bg-grey{
  background-color: #f3f3f3;
}
.bg-white{
  background-color: #fff;
}
.bg-blur{
backdrop-filter: blur(1px) brightness(0.97);
}
.bg-blur-md{
backdrop-filter: blur(3px) brightness(0.97);
}
.bg-blur-xl{
backdrop-filter: blur(5px) brightness(0.97);
}
.bg-primary{
background-color: $primary !important;
}
.bg-primary-01{
background-color: $primary-01;
}
.bg-primary-02{
background-color: $primary-02;
}
.bg-primary-03{
background-color: $primary-03;
}
.bg-neutral{
background-color: $neutral !important;
}
.bg-secondary{
background-color: $secondary !important;
}
.bg-gradient1{
  background-image: $gradient1;
}
.bg-dark-01{
background-color: rgba(0, 0, 0, 0.026);
}
.bg-dark-02{
background-color: #007a190f;
}
.bg-dark-03{
background-color: rgba(0, 0, 0, 0.319);
}
.ar-16-9{
  aspect-ratio: 16/9;
}
.ar-1{
  aspect-ratio: 1/1;
}
.x-scroll{
  overflow-x: scroll;
}
.overflow-hidden{
  overflow: hidden;
}
.white-space-nowrap{
  white-space: nowrap;
}
.word-break{
  word-break: break-all;
}
.hover-scale:hover{
  transform: scale(1.1);
}
.transition-01{
  transition: .1s;
}
.transition-02{
  transition: .2s;
}
.chart-skeleton{
  padding: 30px;
  border-style: solid;
  border-color: var(--body-gradient-2);
  border-width: 0px 0px 10px 10px;
}
.table-skeleton{
  border: solid 5px;
  padding: 30px;
  border-radius: 10px;
  border-color:var(--body-gradient-2);
}
.text-wrap{
   white-space: initial;
}
.y-scroll{
  overflow-y: scroll;
}
/* Red */
.filter-red {
  filter: sepia(100%) saturate(150%) hue-rotate(0deg) brightness(120%);
}

/* Green */
.filter-green {
  filter: sepia(100%) saturate(150%) hue-rotate(90deg) brightness(120%);
}

/* Yellow */
.filter-yellow {
  filter: sepia(100%) saturate(150%) hue-rotate(60deg) brightness(120%);
}

/* Blue */
.filter-blue {
  filter: sepia(100%) saturate(150%) hue-rotate(200deg) brightness(120%);
}

/* Purple */
.filter-purple {
  filter: sepia(100%) saturate(150%) hue-rotate(270deg) brightness(120%);
}

/* Orange */
.filter-orange {
  filter: sepia(100%) saturate(150%) hue-rotate(30deg) brightness(120%);
}

/* Cyan */
.filter-cyan {
  filter: sepia(100%) saturate(150%) hue-rotate(180deg) brightness(120%);
}

/* Pink */
.filter-pink {
  filter: sepia(100%) saturate(150%) hue-rotate(300deg) brightness(120%);
}

/* Teal */
.filter-teal {
  filter: sepia(100%) saturate(150%) hue-rotate(150deg) brightness(120%);
}
.ant-table-filter-dropdown {
    backdrop-filter: blur(5px);
}
// ----------------- css modifica componenti ant ----------------- 
input{
  font-size: 16px !important;
}
.ant-tour {
    max-width: calc(100% - 20px);
}
.ant-picker-filled, .ant-select-filled .ant-select-selector {
    background: rgb(0 0 0 / 2%) !important;
}
.ant-color-picker-inner-content{
  width: 100% !important;
}
.ant-picker-datetime-panel{
  flex-wrap: wrap !important;
}
.ant-divider{
  .ant-divider-inner-text{
      text-wrap: wrap;
      min-width: 180px;
  }
}
.ant-row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.ant-layout-sider {
  backdrop-filter: blur(10px);
  position: fixed !important;
  height: calc(100vh - 40px);
  z-index: 1;
}
.ant-list-item{
  padding: 0px !important;
}
.ant-skeleton{
  width: 100% !important;
}

.ant-drawer-content-wrapper {
  max-width: 100%;
}
.ant-table-row:hover, .ant-table-cell-row-hover {
    background: #05772f24 !important;
}
tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
    padding: 20px;
    border: solid 1px $primary-02;
}
.ant-carousel .slick-dots li{
  &.slick-active button {
    background-color: $primary; /* Change active dot color */
  }
  button {
    background-color: #86868670; /* Change inactive dot color */
  }
}
.ant-tabs-tab {
    margin: 0px 5px 0px 5px !important;
}
.ant-modal-mask{
  backdrop-filter: blur(3px);
}
.ant-card{
  box-shadow: unset !important;

  .ant-card-head, .ant-card-body, .ant-card-actions{
    background: rgba(146, 146, 146, 0.04) !important;
  } 
  .ant-card-body{
    border-radius: 0px !important;
  }
  
}
.ant-select-selector, .ant-input-number {
  height: 32px !important; /* Regola questa altezza per adattarla */
  display: flex !important;
  align-items: center !important;
}
.ant-collapse-content-box{
  padding: 10px !important;
}
.ant-drawer-mask {
    backdrop-filter: blur(7px);
}
.ant-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
// ----------------- css modifica componenti ant ----------------- 


// ----------- css layout -----------
.di-header{
  max-height: 60px;
  padding: 10px;
  width: 100%;
  backdrop-filter:blur(2px);
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--mobile-header-bg);

  & > div{
    height: 100%;
  }
  .di-show-drawer {
    color:$primary;
    font-size: 25px;
  }
}
.di-logo-extended{
  width: 120px;
}
.di-dashboard{
  background-image: url("../public/background.png");
  background-color: $primary-04;
  background-size: 100% 100% !important;
  
  .ant-layout{
    background: unset;
  }
  .ant-layout-content{
    margin-left: 200px;
    padding: 0px !important;
    gap: 10px;
  }
}
.di-card{
  background: var(--primary-card-background);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
  border: solid 0px transparent;
  transition: 0.2s;
  box-shadow: $shadow2;
}
.di-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}

.di-chart-wrapper{
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: 2px;  /* Firefox */

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}
.di-sidebar{
  box-shadow: $shadow2;
}
.di-navigation-menu-wrapper{
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 180px);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: rgba(113, 113, 113, 0.154);
      margin-top: 10px;
      margin-bottom: 600px;
      border-radius: 200px;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }
}
.di-mobile-menu{

  .ant-drawer-body, .ant-drawer-header{
    padding: 10px;
  }

}
.di-navigation{
  border-inline-end:unset !important;

  &.primary{
    //height: calc(100% - 110px);
  }
  .ant-menu-item-selected {
    color: #fff;
    background-color: #008833;
  }
  .di-menu-logout{
    color: #008833;
    background: #f7f8fa;
  }
}
.ant-menu-light{
  background: transparent;
}
.pie-chart{
  height: 500px;
}

.show-more-wrapper{

  position: relative;

  .show-more-content{
    max-height: 400px;
    overflow: hidden;
    transition: .2s;
    padding-bottom: 50px;
    -webkit-mask-image: linear-gradient(#fff 70%, transparent);
    mask-image: linear-gradient(#fff 70%, transparent);
    &.show{
      max-height: 2000px;
      margin-bottom: 50px;
      mask-image: none;
      -webkit-mask-image: none;
    }
  }

  .show-more-button{
    position: absolute;
    bottom:0;
    z-index: 10;
  }

}

.opened-table-modal{
  .ant-modal-body{
    .ant-row{
      padding: 5px 10px;
    }
    .ant-row:nth-child(odd){
      background-color: var(--primary-card-background);
    }
    .ant-row:nth-last-child(1){
      background-color: var(--primary-card-background);
    }
  }
}

.di-sider-actions-wrapper{
    border: solid;
    padding: 5px;
    border-radius: 12px;
    border-width: 0px;
    border-color:$element-border-color;
    .ant-btn{
      border: unset;
    }
}
.beta-badge{
  font-size: 8px;
  color: $light-grey;
  position: absolute;
}
.di-fab-wrapper{
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  button{
    position:unset;
  }
}
.changelog-modal{
  margin: 40px 10px;
}
.di-secondary-btn {
    border: unset;
    border: 1px solid transparent;
}
.di-logo{
  max-width: 150px;
}
.react-resizable-handle {
    
    cursor: se-resize;

    &:after{
      border-color: $primary !important;
    }

    &.react-resizable-handle-ne{
      top: 10px !important;
      right: 10px !important;
    }
    &.react-resizable-handle-se{
      bottom: 10px !important;
      right: 10px !important;
    }
    &.react-resizable-handle-nw{
      top: 10px !important;
      left: 10px !important;
    }
    &.react-resizable-handle-sw{
      bottom: 10px !important;
      left: 10px !important;
    }
}

.react-draggable{
  .di-card {
    border: solid 1px rgba(255, 0, 0, 0.351)
  }
}
.di-screen-badge{
  background-color: $ant-volcano-7;
  color: #fff;
  font-weight: bold;
  z-index: 999;
  transform: rotate(45deg);
  width: 120px;
  opacity: 0;
  right: -225px;
  top: -225px;
  text-align: center;
  position: absolute !important;
  transition: .2s;
  &.active{
    opacity: 1;
    right: -25px;
    top: 25px;
  }
}
.di-fab-reset-layout{
  bottom: 80px;
  .ant-float-btn-body{
    background-color: $ant-volcano-9;
  }
}
.di-news-covers {
    max-height: 350px;
    height: 350px;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
    .img-wrapper{
      padding: 20px;
      border-radius: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      img{
        border-radius: 20px;
        width: 100%;
        max-height: 350px;
      }
    }
}
.di-corrections-ribbon{
  bottom: -2px;
}
// ----------- css layout -----------


// ----------- css marketing -----------

.di-no-content-wrapper{
  border:solid 1px rgba(99, 99, 99, 0.354);
  justify-content: center;
  flex-direction: column;
  border-style: dashed;
  border-radius: 15px;
  align-items: center;
  padding: 10px;
  display: flex;
  gap:5px;
}
.di-create-content-drawer{
  .ant-drawer-body{
    padding: 0;
  }
} 
.di-create-audience-drawer{
  .ant-drawer-body{
    padding: 0;
  }
  .ant-scroll-number-only{
    color: white;
    font-weight: bold;
  }
} 
.di-filters-preset-card{
  margin-bottom: 20px;
  .ant-card-body{
    min-height: 200px;
  }
}
.di-audience-collapse{
  background-color: unset;
  border: solid;
  border-radius: 0px;
  border-width: 1px 0px 1px 0px;
  border-color: $element-border-color;
}
.di-tab-audience-filter{
  padding: 10px;
}
// ----- gestione avanzata colori tabs ------

$filter-color-profile:$primary;
$filter-color-fidelity:$ant-orange-6;
$filter-color-ecommerce:$ant-cyan-7;
$filter-color-users:$ant-purple-5;
$filter-color-softwares:$ant-yellow-6;

.di-audience-filters-group-tabs{
  .filters-group-profile{
    .bg-dark-02, .di-filters-group-wrapper, .ant-collapse-item{
      background-color: rgba($filter-color-profile, 0.035) !important;
    }
  }
  .filters-group-fidelity{
    .bg-dark-02, .di-filters-group-wrapper, .ant-collapse-item{
      background-color: rgba($filter-color-fidelity, 0.035) !important;
    }
  }
  .filters-group-ecommerce{
    .bg-dark-02, .di-filters-group-wrapper, .ant-collapse-item{
      background-color: rgba($filter-color-ecommerce, 0.035) !important;
    }
  }
  .filters-group-users{
    .bg-dark-02, .di-filters-group-wrapper, .ant-collapse-item{
      background-color: rgba($filter-color-users, 0.035) !important;
    }
  }
  .filters-group-softwares{
    .bg-dark-02, .di-filters-group-wrapper, .ant-collapse-item{
      background-color: rgba($filter-color-softwares, 0.035) !important;
    }
  }
  .ant-tabs-nav{
    margin-bottom: unset;
  }
  .ant-tabs-tab{
    &[data-node-key="1"]{
      background-color: rgba($filter-color-profile, 0.05);
      & * {
        color: $filter-color-profile;
      }
      &.ant-tabs-tab-active{
        background-color: $filter-color-profile
      }
    }
    &[data-node-key="2"]{
      background-color: rgba($filter-color-fidelity, 0.05);
      & * {
        color: $filter-color-fidelity;
      } 
      &.ant-tabs-tab-active{
        background-color: $filter-color-fidelity
      }
    }
    &[data-node-key="3"]{
      background-color: rgba($filter-color-ecommerce, 0.05);
      & * {
        color: $filter-color-ecommerce;
      } 
      &.ant-tabs-tab-active{
        background-color: $filter-color-ecommerce;
      }
    }
    &[data-node-key="4"]{
      background-color: rgba($filter-color-users, 0.05);
      & * {
        color: $filter-color-users;
      } 
      &.ant-tabs-tab-active{
        background-color: $filter-color-users;
      }
    }
    &[data-node-key="5"]{
      background-color: rgba($filter-color-softwares, 0.05);
      & * {
        color: $filter-color-softwares;
      } 
      &.ant-tabs-tab-active{
        background-color: $filter-color-softwares;
        & * {
          color: #000 !important;
        }
      }
      & * {
         color:goldenrod;
      }
    }
    &.ant-tabs-tab-active *{
      color: white;
    }
  }
}


$color-whatsapp:$primary;
$color-coupons:$ant-orange-6;
$color-email:$ant-cyan-7;
$color-sms:$ant-purple-5;
$color-push-notification:$ant-yellow-6;

.operation-chekbox-wrapper{
  &.operation-whatsapp{
      background-color: rgba($color-whatsapp, 0.02);
      & * {
        color: $color-whatsapp;
      }
  }
  &.operation-sms{
      background-color: rgba($color-sms, 0.05);
       & * {
        color: $color-sms;
      }
  }
  &.operation-email{
      background-color: rgba($color-email, 0.03);
       & * {
        color: $color-email;
      }
  }
  &.operation-coupons{
      background-color: rgba($color-coupons, 0.03);
       & * {
        color: $color-coupons;
      }
  }
  &.operation-push-notification{
      background-color: rgba($color-push-notification, 0.03);
        & * {
        color: goldenrod;
      }
  }
}
.channel-priority-table{
  [data-row-key="whatsapp"]{
      background-color: rgba($color-whatsapp, 0.04);
      & * {
        color: $color-whatsapp;
      }
  }
  [data-row-key="sms"]{
      background-color: rgba($color-sms, 0.05);
       & * {
        color: $color-sms;
      }
  }
  [data-row-key="email"]{
      background-color: rgba($color-email, 0.03);
       & * {
        color: $color-email;
      }
  }
  [data-row-key="coupons"]{
      background-color: rgba($color-coupons, 0.03);
       & * {
        color: $color-coupons;
      }
  }
  [data-row-key="push-notification"]{
      background-color: rgba($color-push-notification, 0.03);
        & * {
        color: $color-push-notification;
      }
  }
}
.di-operations-group-tabs{
  label{
    text-wrap: wrap !important;
  }
  .operations-group-sms{
    .bg-dark-02{
      background-color: rgba($color-sms, 0.035) !important;
    }
    .device-topbar, .device-navbar{
      background-color: rgba($color-sms, 0.4) !important;
    }
    .device-content, .bg-dark-02{
      background-color: rgba($color-sms, 0.05) !important;
    }
  }
  .operations-group-whatsapp{
    .bg-dark-02{
      background-color: rgba($color-whatsapp, 0.035) !important;
    }
    .device-topbar, .device-navbar{
      background-color: rgba($color-whatsapp, 0.4) !important;
    }
    .device-content, .bg-dark-02{
      background-color: rgba($color-whatsapp, 0.05) !important;
    }
    .di-preview-wa-image{
      max-height: 300px;
    }
  }
  .operations-group-push-notification{
    .bg-dark-02{
      background-color: rgba($color-push-notification, 0.035) !important;
    }
    .device-topbar, .device-navbar{
      background-color: rgba($color-push-notification, 0.4) !important;
    }
    .device-content, .bg-dark-02{
      background-color: rgba($color-push-notification, 0.05) !important;
    }
  }
  .operations-group-coupons{
    .bg-dark-02, .di-operations-group-wrapper, .ant-collapse-item{
      background-color: rgba($color-coupons, 0.035) !important;
    }
    .device-topbar, .device-navbar{
      background-color:rgba($color-coupons, 0.7) !important;
    }
    .device-content, .bg-dark-03{
      background-color:rgba($color-coupons, 0.2) !important;
    }
    .di-preview-coupon-cover{
      aspect-ratio: 21/9;
      img{
        object-fit: cover;
      }
    }
  }
  .operations-group-email{
    .bg-dark-02, .di-operations-group-wrapper, .ant-collapse-item{
      background-color: rgba($color-email, 0.035) !important;
    }
    .device-topbar, .device-navbar{
      background-color:rgba($color-email, 0.7) !important;
    }
    .device-content, .bg-dark-03{
      background-color:rgba($color-email, 0.2) !important;
    }
  }
  .ant-tabs-nav{
    margin-bottom: unset;
  }
  .ant-tabs-tab{
    &[data-node-key="whatsapp"]{
      background-color: rgba($color-whatsapp, 0.05);
      & * {
        color: $color-whatsapp;
      }
      &.ant-tabs-tab-active{
        background-color: $color-whatsapp
      }
    }
    &[data-node-key="coupon"]{
      background-color: rgba($color-coupons, 0.05);
      & * {
        color: $color-coupons;
      } 
      &.ant-tabs-tab-active{
        background-color: $color-coupons
      }
    }
    &[data-node-key="email"]{
      background-color: rgba($color-email, 0.05);
      & * {
        color: $color-email;
      } 
      &.ant-tabs-tab-active{
        background-color: $color-email;
      }
    }
    &[data-node-key="push-notification"]{
      background-color: rgba(goldenrod, 0.05);
      & * {
        color: goldenrod;
      } 
      &.ant-tabs-tab-active{
        background-color: $color-push-notification;
        & * {
          color: #000 !important;
        }
      }
    }
    &[data-node-key="sms"]{
      background-color: rgba($color-sms, 0.05);
      & * {
        color: $color-sms;
      } 
      &.ant-tabs-tab-active{
        background-color: $color-sms;
      }
    }
    &.ant-tabs-tab-active *{
      color: white;
    }
  }
}
// ----- gestione avanzata colori tabs ------

.operation-chekbox-wrapper {
  flex: 1;
  word-break: unset;
  white-space: nowrap;

  .ant-col{
    flex: 1 !important
  }
  .ant-card-body{
    padding: 15px;
  }
}
.di-filters-group-wrapper{
  padding: 10px;
  border-radius: 10px;
  background-color: $light-background;
  .ant-form-item {
    margin: 0px;
  }
}
/* Wrapper padre con flexbox */
.dynamic-date-wrapper {
  display: flex;
  flex-wrap: wrap; /* Consente alle colonne di andare a capo */
  gap: 10px; /* Spazio tra le colonne */
}

/* Classi per le colonne figlie */
.dynamic-date-child {
  flex: 1 1 20%; /* Ogni figlia prenderà almeno il 20% della larghezza */
  min-width: 200px; /* Limita la larghezza minima */
  box-sizing: border-box; /* Per includere padding e bordi nella larghezza */
}

/* Impostazione della struttura base per il dispositivo */
.device-frame {
  position: relative;
  overflow: hidden;
  aspect-ratio: 9/17;
  width: 100%;
  border: solid;
  border-color: #9a9a9a08;
  max-width: 360px; 
  margin: 0 auto;
  background: #4682571a;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  .device-topbar{
    width: 100%;
    height: 40px;
    background: $medium-background;
    padding: 10px 20px;
  }
  .device-content{
    height: 100%;
    overflow: hidden;
  }
  .device-content-appbar{
    width: 100%;
    height: 40px;
    padding: 10px 10px;
    align-items: center0;
    display: flex;
    gap: 5px;
  }
  .device-navbar{
    background: $medium-background;
    padding: 10px 80px;
    width: 100%;
    height: 35px;
  }
}

.device-frame:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.android-ui-background{
  background-size: cover !important;
}
.android-ui-overlay{
  backdrop-filter: blur(25px);
  background-color: #0f161095;
  & * {
    box-shadow: unset;
  }
}
/* Stile base per la nuvoletta */
.bubble {
  display: inline-block;
  max-width: 70%; /* Limita la larghezza massima */
  padding: 10px 15px; /* Spaziatura interna */
  margin: 10px; /* Spaziatura esterna */
  border-radius: 15px; /* Angoli arrotondati */
  font-size: 14px; /* Dimensione del testo */
  line-height: 1.4; /* Altezza delle righe */
  position: relative; /* Posizionamento relativo per il triangolo */
  word-break: break-all;
}

/* Nuvoletta per messaggi ricevuti */
.bubble.received {
  background-color: #e0e0e0; /* Colore di sfondo chiaro */
  color: #333; /* Colore del testo */
  align-self: flex-start; /* Allineamento a sinistra */
}

/* Triangolo della nuvoletta ricevuta */
.bubble.received::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -10px; /* Posizionamento sul lato sinistro */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0; /* Dimensioni del triangolo */
  border-color: transparent #e0e0e0 transparent transparent; /* Colore corrispondente allo sfondo */
}

/* Nuvoletta per messaggi inviati */
.bubble.sent {
  align-self: flex-end; /* Allineamento a destra */
}
.carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Imposta l'altezza per i figli */
}


/* Media query per regolare il numero di colonne in base alla larghezza del contenitore */
@media (max-width: 1200px) {
  .dynamic-date-child  {
    flex: 1 1 30%; /* 3 colonne su schermi medi */
  }
}

@media (max-width: 900px) {
  .dynamic-date-child  {
    flex: 1 1 45%; /* 2 colonne su schermi piccoli */
  }
}

@media (max-width: 600px) {
  .dynamic-date-child  {
    flex: 1 1 100%; /* 1 colonna su schermi molto piccoli */
  }
}
// ----------- css marketing -----------


// ----------- css login -----------
.di-login-form-area {
  border-radius: 40px 0px 0px 40px;
  height: 100vh;
  box-shadow: -20px 0px 1px 1px #f9f9f9, -20px 0px 30px rgb(0 34 64 / 7%);
  padding: 50px;
}
.di-login-image-area img{
  width: 500px;
  padding: 20px;
}
// ----------- css login -----------

// ----------- animazioni -----------
.animate{
  animation-timing-function: ease-in;

}
.fadeIn{
  animation: fadeIn .2s forwards;
}
.fadeInUp{
  animation: fadeInUp .2s forwards;
}
.scaleIn{
  animation: scaleIn .2s forwards;
}
.pulse{
    animation: pulse 3s infinite;
}

@keyframes pulse {
  0%{opacity: 100%}
  50%{opacity: 20%}
  100%{opacity: 100%}
}
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes fadeIn {
  0%{opacity: 0}
  100%{opacity: 1}
}
@keyframes fadeInUp {
  0%{opacity: 0; transform: translateY(20px);}
  100%{opacity: 1;  transform: translateY(0px);}
}
@keyframes scaleIn {
  0%{transform: scale(0);}
  100%{transform: scale(1);}
}
//media query
@media all and (max-width:350px) {
  .ant-popover.ant-color-picker{
    top: 0 !important;
    left: 0 !important;
  }
}
@media all and (max-width:600px) {
  .ant-picker-date-panel, .ant-picker-header, .ant-picker-body, .ant-picker-content, .ant-picker-time-panel{
    width: 100% !important;
  }
  .ant-picker-dropdown, .ant-popover-inner {
      width: calc(100% - 20px);
      left: 10px !important;
  }
  .ant-picker-panel-layout{
    flex-wrap: wrap !important;
  }
  .mobile-w-100{
    width: 100%;
  }
  .ant-picker-presets{
    height: 50px;
    max-width: 100vw !important;

    & li{
      display: inline;
    }
  }
  .pie-chart{
    height: 300px;
  }
}
@media all and (min-width: 990px){
  .di-sidebar{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 20px;
  }
  .mobile-hide{
    display: block;
  }
  .mobile-show{
    display: none !important;
  }
  .desktop-hide{
    display: none;
  }
}
@media all and (max-width: 990px){
  .mobile-hide{
    display: none !important;
  }
  .ant-layout-content{
    margin-left: 0px !important;
  }
  .mobile-show{
    display: block;
  }
  .mobile-x-scroll{
    overflow-x: scroll;
  }
  .di-dashboard  .ant-layout-content{
    margin-top: 70px !important;
  }
  .di-navigation.primary {
    //height: calc(100% - 55px);
  }
  .di-login-wrapper{
    justify-content: start;

    .ant-row{
      display: block;
      height: 100%;
    }
  }
  .di-login-image-area, .di-login-form-area {
    height: auto;
  }
  .di-login-image-area{
    height: 25%;
  }
  .di-login-image-area img{
    width: 300px;
  }
  .di-login-form-area {
    border-radius: 40px 40px 0px 0px !important;
    height: 75%;
    box-shadow: 0px -20px 1px 1px #f9f9f9, 0px -20px 30px rgb(0 34 64 / 7%);

    .di-login-form-wrapper{
      justify-content: flex-start;
    }
    
  }
}
